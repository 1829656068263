<template>
  <div>
    <div class="fbk-header py-4"><img src="../assets/logo.png" /> FERILBÓK</div>
    <div v-for="error in errorList" :key="error.text" class="systemErrorMessage">
      {{ error.text }}
    </div>
    <div v-for="warning in warningList" :key="warning.text" class="systemWarningMessage">
      {{ warning.text }}
    </div>
    <div v-for="info in infoList" :key="info.text" class="systemInfoMessage">
      {{ info.text }}
    </div>
    <b-container>
      <b-row align-h="center" class="mt-5">
        <b-col cols="12" md="6" lg="6">
          <b-card no-body class="text-center">
            <div class="card-body">
              <h4 class="mb-3">Innskráning</h4>

              <p>Smelltu á takkann hér fyrir neðan til þess að skrá þig inn í gegnum island.is.</p>

              <span v-if="loading"><i class="fa fa-spin fa-spinner"></i></span>

              <a v-else :href="url" class="btn btn-lg btn-secondary btn-login"> Innskráning </a>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '../api/auth';

export default {
  name: 'login',
  data() {
    return {
      loading: false,
      url: process.env.VUE_APP_ISLAND_URL,
      warningList: [],
      errorList: [],
      infoList: [],
    };
  },
  methods: {
    // Innskráning í kerfið. Tekur við tokeni og staðfestingu frá island.is
    async login(token, book) {
      this.loading = true;
      try {
        const response = await auth.checkLogin(token);
        if (response.data.successful === true) {
          auth.setToken(response.data.token);
          auth.setTokenExpire(response.data.timeleft);
        }
        if (book) {
          this.$router.push({ name: 'CareerbookById', params: { id: book } });
        } else {
          this.$router.push({ name: 'Index' });
        }
      } catch (e) {
        this.$log.error(e);
        if (e && e.response && e.response.status === 404) {
          this.displayError('Ekki fannst aðgangur fyrir valda kennitölu.');
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading = false;
      }
    },
    async ssoLogin(A, kt, token) {
      this.loading = true;
      try {
        const response = await auth.checkLoginSSO({ A, kt, token });
        if (response.data.successful === true) {
          auth.setToken(response.data.token);
          auth.setTokenExpire(response.data.timeleft);
          this.$router.push({ name: 'Index' });
        } else {
          this.displayError('Ekki fannst aðgangur fyrir valda kennitölu.');
        }
      } catch (e) {
        this.$log.error(e);
        if (e && e.response && e.response.status === 404) {
          this.displayError('Ekki fannst aðgangur fyrir valda kennitölu.');
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading = false;
      }
    },
    async getSystemErrorStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLogin('error');
        this.errorList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getSystemWarningStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLogin('warning');
        this.warningList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getSystemInfoStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLogin('info');
        this.infoList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },
    /**
     * Annað
     */
    ...mapActions(['displaySuccess', 'displayError']),
  },

  // Innskráning eftir umhverfi
  created() {
    if ('token' in this.$route.params) {
      this.login(this.$route.params.token, this.$route.params.book);
    } else if ('token' in this.$route.query) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      const kt = queryParams.get('kt'); // personalId
      const a = queryParams.get('A'); // userId
      if (token && kt && a) {
        this.ssoLogin(a, kt, token);
      }
    }
    this.getSystemErrorStatus();
    this.getSystemWarningStatus();
    this.getSystemInfoStatus();
  },
};
</script>

<style lang="scss" scoped>
@import '../style/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.btn-login {
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.fbk-header {
  background-color: $fbk-header-bg;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 0.9rem;

  img {
    height: 32px;
  }
}

.systemErrorMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(247, 95, 95);
}

.systemWarningMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(255, 255, 128);
}

.systemInfoMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: #acd4ff;
}
</style>
