import Vue from 'vue';

// import jwtDecode from 'jwt-decode';
import store from 'store';

import config from '../config';

export default {
  /**
   * Vefþjónusta til þess að athuga hvort notandi sé til.
   */
  userCheck(ssn) {
    return Vue.http
      .get(config.USER_CHECK_URL.replace('{ssn}', encodeURIComponent(ssn)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta sem sækir system status (villu-, viðvörunar- og upplýsingaskilaboð)
   * fyrir síður á login síðunni
   *
   */
  showSystemStatusLogin(type) {
    return Vue.http
      .get(
        config.SHOW_SYSTEM_STATUS_LOGIN,
        {
          params: {
            type,
          },
        },
        { skipAuthorization: true },
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta sem sækir system status (villu-, viðvörunar- og upplýsingaskilaboð)
   * fyrir síður logged in
   *
   */
  showSystemStatusLoggedIn(type) {
    return Vue.http
      .get(
        config.SHOW_SYSTEM_STATUS_LOGGED_IN,
        {
          params: {
            type,
          },
        },
        { skipAuthorization: true },
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir notendur að kerfinu.
   */
  userList(page = null, limit = null, searchName = null, searchInstitution = null) {
    return Vue.http
      .get(config.USER_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
          searchInstitution: searchInstitution !== null ? searchInstitution.id : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir nema ferilbóka í kerfinu.
   */
  bookUserList(page = null, limit = null, searchName = null, searchInstitution = null) {
    return Vue.http
      .get(config.USER_BOOK_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
          searchInstitution: searchInstitution !== null ? searchInstitution.id : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir umsýsluaðila ferilbóka í kerfinu.
   */
  instructorBookUserList(page = null, limit = null, searchName = null, searchInstitution = null) {
    return Vue.http
      .get(config.USER_BOOK_INSTRUCTOR_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
          searchInstitution: searchInstitution !== null ? searchInstitution.id : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að sækja yfirlit yfir tilsjónaraðila ferilbóka í kerfinu.
   */
  supervisorBookUserList(page = null, limit = null, searchName = null, searchInstitution = null) {
    return Vue.http
      .get(config.USER_BOOK_SUPERVISOR_LIST_URL, {
        params: {
          page,
          limit,
          searchName: searchName !== '' ? searchName : null,
          searchInstitution: searchInstitution !== null ? searchInstitution.id : null,
        },
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja einn notanda.
   *
   * @param {number} id Einkenni notanda til að sækja.
   */
  userDetail(id) {
    return Vue.http
      .get(config.USER_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að skrá notanda.
   *
   * @param {object} data Gögn um nýjan notanda.
   */
  userCreate(data) {
    return Vue.http
      .post(config.USER_LIST_URL, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að uppfæra notanda.
   *
   * @param {number} id Einkenni notanda til að uppfæra.
   * @param {object} data Gögn um breyttan notanda.
   */
  userUpdate(id, data) {
    return Vue.http
      .put(config.USER_DETAIL_URL.replace('{id}', encodeURIComponent(id)), data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að eyða notanda.
   *
   * @param {number} id Einkenni notanda til að eyða.
   */
  userDelete(id) {
    return Vue.http
      .delete(config.USER_DETAIL_URL.replace('{id}', encodeURIComponent(id)))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
  /**
   * Vefþjónusta til þess að tékka á onetime key.
   *
   * @param {String} payload JWT innskráning til að fríska upp á.
   */
  checkLogin(token) {
    return Vue.http
      .post(config.CHECK_LOGIN_URL, { onetime: token }, { skipAuthorization: true })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  checkLoginSSO(data) {
    return Vue.http
      .post(config.CHECK_LOGIN_SSO_URL, data, { skipAuthorization: true })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  getUserAccess() {
    return Vue.http
      .get(config.USER_ACCESS_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  getUrlToChangeSystem(loginType, userId, url) {
    return Vue.http
      .post(config.CHANGE_SYSTEM, {
        userId,
        loginType,
        url,
      })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að fríska upp á innskráningu.
   *
   * @param {String} payload JWT innskráning til að fríska upp á.
   */
  // refresh(token) {
  // return Vue.http.post(config.REFRESH_URL, { token }, { skipAuthorization: true })
  // .then(response => Promise.resolve(response))
  // .catch(error => Promise.reject(error));
  // },

  /**
   * Vefþjónusta til þess að athuga með innskráningu.
   */
  ping() {
    return Vue.http
      .get(config.PING_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Vefþjónusta til þess að sækja innskráðan notanda.
   */
  me() {
    return Vue.http
      .get(config.ME_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  /**
   * Setja token
   *
   * @param {String} toke
   */
  setToken(token) {
    store.set(config.LOGIN_TOKEN, token);
  },

  /**
   * Hreinsa innskráningu.
   */
  removeToken() {
    store.remove(config.LOGIN_TOKEN);
    store.remove(config.LOGIN_TOKEN_EXPIRE);
  },

  /**
   * Sækja innskráningu.
   */
  getToken() {
    return store.get(config.LOGIN_TOKEN);
  },

  /**
   * Setja expire date
   *
   * @param {String} toke
   */
  setTokenExpire(timeleft) {
    const d = new Date();
    d.setTime(d.getTime() + parseInt(timeleft, 10) * 1000);
    store.set(config.LOGIN_TOKEN_EXPIRE, d);
  },

  /**
   * Sækja expire date.
   */
  getTokenExpire() {
    return store.get(config.LOGIN_TOKEN_EXPIRE);
  },

  /**
   * Sækja innihald af JWT innskráningu.
   */
  // getPayload() {
  // return store.get(config.LOGIN_TOKEN) ? jwtDecode(store.get(config.LOGIN_TOKEN)) : null;
  // return store.get(config.LOGIN_TOKEN) ? store.get(config.LOGIN_TOKEN) : null;
  // },

  /**
   * Sækja upplýsingar um það hvenær JWT kóði rennur út.
   *
   * @param {String} decoded Innihald JWT innskráningar.
   */
  // getTokenExpirationDate(decoded) {
  // if (!decoded || !('exp' in decoded) || typeof decoded.exp === 'undefined') {
  // return null;
  // }
  // const d = new Date(0); // The 0 here is the key, which sets the date to the epoch
  // d.setUTCSeconds(decoded.exp);
  // return d;
  // },

  /**
   * Athuga hvort JWT innskráning sé runnin út.
   *
   * @param {String} decoded Innihald JWT innskráningar.
   */
  isTokenExpired() {
    const jwtTokenExpires = this.getTokenExpire();
    if (!jwtTokenExpires) {
      return true;
    }
    return new Date().getTime() > new Date(jwtTokenExpires);
  },
};
