import Vue from 'vue';
import Router from 'vue-router';

import RegisterContract from '@/components/contract/RegisterContract.vue';
import Index from '@/views/Index.vue';
import Login from '@/views/Login.vue';
import UserList from '@/views/UserList.vue';
import ProfessionList from '@/views/ProfessionList.vue';
import InstitutionList from '@/views/InstitutionList.vue';
import ContractList from '@/views/ContractList.vue';
import Careerbook from '@/views/Careerbook.vue';
import CareerbookApplicationList from '@/views/CareerbookApplicationList.vue';
import RegulationList from '@/views/RegulationList.vue';
import WorkplaceList from '@/views/WorkplaceList.vue';
import CareerbookList from '@/views/CareerbookList.vue';
import CreateCareerbookForm from '@/components/careerbook/create/CreateForm.vue';
import EditCareerbookForm from '@/components/careerbook/create/EditForm.vue';
import SendContract from '@/components/contract/SendContract.vue';
import VersionList from '@/views/VersionList.vue';
import ProfessionVersionUpload from '@/components/version/ProfessionVersionUpload.vue';
import Statistics from '@/views/statistics/Statistics.vue';

import guards from './guards';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/login/:token',
      name: 'LoginToken',
      component: Login,
      beforeEnter: guards.logoutBefore,
    },
    {
      path: '/ssologin',
      name: 'LoginSSO',
      component: Login,
      beforeEnter: guards.logoutBefore,
    },
    {
      path: '/login/:token/:book',
      name: 'LoginTokenBook',
      component: Login,
      beforeEnter: guards.logoutBefore,
    },
    {
      path: '/notendur',
      name: 'UserList',
      component: UserList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canRead]),
    },
    {
      path: '/starfsgreinar',
      name: 'ProfessionList',
      component: ProfessionList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canRead]),
    },
    {
      path: '/stofnanir',
      name: 'InstitutionList',
      component: InstitutionList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.isAdmin]),
    },
    {
      path: '/samningar',
      name: 'ContractList',
      component: ContractList,
      beforeEnter: guards.requireAuth,
      // beforeEnter: guards.multiguards([guards.requireAuth, guards.isAdmin]),
    },
    {
      path: '/starfsgrein/:profession',
      name: 'Careerbook',
      component: Careerbook,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/utgafa/:profession/:version',
      name: 'CareerbookVersion',
      component: Careerbook,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/utgafa/innlestur/:profession/:version',
      name: 'ProfessionVersionUpload',
      component: ProfessionVersionUpload,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/ferilbok/:id',
      name: 'CareerbookById',
      component: Careerbook,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/leidbeiningar',
      name: 'RegulationList',
      component: RegulationList,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/vinnustadir',
      name: 'WorkplaceList',
      component: WorkplaceList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canEdit]),
    },
    {
      path: '/umsaekjendur',
      name: 'CareerbookApplicationList',
      component: CareerbookApplicationList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canRead]),
    },
    {
      path: '/ferilbaekur',
      name: 'CareerbookList',
      component: CareerbookList,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canRead]),
    },
    {
      path: '/virkja',
      name: 'CreateCareerbookForm',
      component: CreateCareerbookForm,
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canCreateBook]),
    },
    {
      path: '/breyta/:profession/:id',
      name: 'EditCareerbookFrom',
      component: EditCareerbookForm,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/senda/:id',
      name: 'SendContract',
      component: SendContract,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/skra/:id',
      name: 'RegisterContract',
      component: RegisterContract,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/utgafur',
      name: 'VersionList',
      component: VersionList,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "version-list" */ '@/views/VersionList.vue'),
      beforeEnter: guards.multiguards([guards.requireAuth, guards.canRead]),
    },
    {
      path: '/tolfraedi',
      name: 'StatisticsList',
      component: Statistics,
      beforeEnter: guards.canViewStatistics,
    },
  ],
});
