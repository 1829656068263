<template>
  <div>
    <table class="table">
      <tr>
        <th style="padding-right: 1em">
          <b-form-group label="Dagsetning frá" label-for="date-from-picker">
            <flat-pickr id="date-from-picker" name="date-from-picker" class="form-control mb-2" type="text"
              v-model="dateFrom" @input="loadList()"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
              :config="flatpickrConfig" data-vv-as="dags frá" ref="dateFromPickr"></flat-pickr>
          </b-form-group>
        </th>
        <th>
          <b-form-group label="Dagsetning til" label-for="date-to-picker">
            <flat-pickr id="date-to-picker" name="date-to-picker" class="form-control mb-2" type="text" v-model="dateTo"
              @input="loadList()"
              :class="{ 'is-invalid': submitted && (errors.has('date_to') || invalid.date == true) }"
              :config="flatpickrConfig" data-vv-as="dags til" ref="dateToPickr"></flat-pickr>
          </b-form-group>
        </th>
      </tr>
    </table>

    <b-alert show>
      <ul style="margin: 0">
        <li>Sóttir eru allir nemendur sem voru skráðir í kerfið fyrir lokadagsetningu tímabils.</li>
        <li>Ef nemandi er með margar ferilbækur og/eða samninga þá er hann birtur einu sinni fyrir hverja ferilbók og
          samning.</li>
        <li>
          Aðeins eru birtar ferilbækur sem voru stofnaðar innan tímabils, eða fyrir dagsetningu ef &bdquo;frá&rdquo; og
          &bdquo;til&rdquo; er
          sama dagsetning.
        </li>
        <li>
          Það er ekkert í kerfinu sem tengir vinnustað við samning. Þar með, ef sami nemandi kemur oft fyrir, geta
          vinnustaðir og samningar
          víxlast. En eru þó alltaf tengd við réttan nemanda.
        </li>
      </ul>
    </b-alert>

    <b-button @click="downloadExcel()" v-b-tooltip.hover title="Taka upplýsingar út í Excel">
      <i class="fa fa-fw fa-file-excel-o"></i>
    </b-button>
    <div style="width: 100%; overflow-y: scroll">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Netfang</th>
            <th>Heimilisfang</th>
            <th>Póstnúmer</th>
            <th>Sveitarfélag</th>
            <th>Aldur</th>
            <th>Kyn</th>
            <th>Framhaldsskóli</th>
            <th>Vinnustaður</th>
            <th>Starfsgrein</th>
            <th>Samningur</th>
            <th v-b-tooltip.hover
              title="Er óvirkt ef valið tímabil er alfarið fyrir utan tímabil feribókar (byrjunar- og lokadagsetningu). Annars virk.">
              Ferilbók virk/óvirk <i class="fa fa-info-circle"></i>
            </th>
            <th v-b-tooltip.hover title="Byrjunardagsetning feribókar">Dags. virkjuð <i class="fa fa-info-circle"></i>
            </th>
            <th v-b-tooltip.hover title="Lokadagsetning ferilbókar">Lokadags. FB <i class="fa fa-info-circle"></i></th>
            <th v-b-tooltip.hover title="Fjöldi vikna milli byjunar- og lokadagsetningu ferilbókar">
              Tímalengd FB (í vikum) <i class="fa fa-info-circle"></i>
            </th>
            <th v-b-tooltip.hover title="Nýjasta staða samnings innan þess tímabils sem er leitað eftir">
              Staða samnings <i class="fa fa-info-circle"></i>
            </th>
            <th v-b-tooltip.hover title="Undirskriftardagsetning samnings">Dags. samningur vikjaður <i
                class="fa fa-info-circle"></i></th>
            <th v-b-tooltip.hover title="Dagsetning þess þegar síðasti verkþáttur ferilbókar var kláraður">
              Lokadags. samnings <i class="fa fa-info-circle"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="studentList.length == 0">
            <td colspan="100">
              <i>Engir nemendur fundust með gefnum forsendum.</i>
            </td>
          </tr>
          <tr class="table-row-linkable" v-for="(student, index) in studentList" :key="index">
            <td>{{ student.name }}</td>
            <td>{{ student.ssn }}</td>
            <td>{{ student.email }}</td>
            <td>{{ student.address }}</td>
            <td>{{ student.postcode }}</td>
            <td>{{ student.city }}</td>
            <td>{{ student.age }}</td>
            <td>{{ student.gender }}</td>
            <td>{{ student.school }}</td>
            <td>{{ student.workplace }}</td>
            <td>{{ student.profession }}</td>
            <td>{{ student.contract_type }}</td>
            <td>{{ student.active }}</td>
            <td>{{ student.date_from }}</td>
            <td>{{ student.date_to }}</td>
            <td>{{ student.timespan_weeks | comma }}</td>
            <td>{{ student.status }}</td>
            <td>{{ student.data_contract_activated }}</td>
            <td>{{ student.contract_closing_date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination v-if="pagination" :pagination-response="pagination" @change-limit="changeLimit"
      @change-page="changePage"></pagination>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import statistics from '@/api/statistics';
import Pagination from '@/components/Pagination.vue';

import { paginationMixin, parsePagination, flatpickrConfig } from '@/utils';

export default {
  name: 'student-statistics-list',
  mixins: [paginationMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      studentList: [],
      dateFrom: null,
      dateTo: null,
      pagination: null,
      flatpickrConfig: flatpickrConfig(),
    };
  },
  filters: {
    comma(num) {
      return num?.toString()?.replace('.', ',');
    },
  },
  methods: {
    /*
     * Sækja tölfræði nemenda.
     */
    async loadList(page = undefined, limit = undefined) {
      this.loading = true;
      this.pagination = null;
      this.studentList = [];

      try {
        const response = await statistics.students(this.dateFrom, this.dateTo, page, limit);
        this.studentList = response.data.items;

        this.pagination = parsePagination(response.data.extra, response.data.items);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    downloadExcel() {
      statistics.studentsToExcel(this.dateFrom, this.dateTo);
    },
    /**
     * Annað
     */
    ...mapActions(['displayError']),
  },
  created() {
    const today = new Date();
    this.dateTo = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    this.dateFrom = `${today.getFullYear() - 1}-${today.getMonth() + 1}-${today.getDate()}`;
    this.loadList();
  },
};
</script>
